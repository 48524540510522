import { mapActions, mapState } from 'vuex'

import OpenTicket from '../../../modules/management/ticket/pages/ticket/OpenTicket'

export default{
    
  data: () => ({
        loading: false,
        items: [],
        search: null,
        tab: null,
        showOpenTicket: false
    }),
    mounted (){
      this.getData();
    },

    computed: {
      ...mapState('auth', ['acesso']),
      isMobile() {
        return this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm';
      }
    },
    components: {
      OpenTicket
    },
    methods: {
      ...mapActions('faqCategory', ['ActionFindFaqCategories']),
        
      getData(search = null){
      
        const params = {
            search: search,
            filter_relations: [
              {
                name:   'faqs',
                key:    'active', 
                value:  1,
                innerFilter: true,
              }
            ],
            find_columns: {
              active: 1
            }
        };

        this.loading = true;
        this.items = [];
        
        this.ActionFindFaqCategories(params)
            .then((res) => {
                this.items = res.data;
            })
            .finally(() => {
                this.loading = false;
                this.setTab();
            })
    },
    
    changeText(search){
      
      if(!search){
        setTimeout(() => {  

          if(!this.search){
            this.getData(null);
          }
        }, 2000);
      }

      
    },

    setTab()
    {
      if(this.$route.query.tab){
        this.tab = this.$route.query.tab;
      }
    }
    
  },

}
